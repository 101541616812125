import React, { useEffect, useState } from 'react';
import "./nft.scss";
import Back from '../Back';
import { useTranslation } from 'react-i18next';

import fon from "./fon.png";
import common from "./common1.png";
import rare from "./rare2.png";
import epic from "./epic3.png";
import legend from "./legenda4.png";
import pointer from "./pointer.png"

import { getNftCountNumber } from '../../http';
import { openLink } from '../../Utils/telegramUtils';

function Countdown({ endDate }) {
    
  const calculateTimeLeft = () => {
      const difference = +new Date(endDate) - +new Date();
      let timeLeft = {};

      if (difference > 0) {
          timeLeft = {
              days: Math.floor(difference / (1000 * 60 * 60 * 24)),
              hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
              minutes: Math.floor((difference / 1000 / 60) % 60),
              seconds: Math.floor((difference / 1000) % 60),
          };
      }

      return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
      const timer = setTimeout(() => {
          setTimeLeft(calculateTimeLeft());
      }, 1000);

      return () => clearTimeout(timer);
  }, [timeLeft]);

  return (
      <div className="countdown_nft">
          {Object.keys(timeLeft).length > 0 ? (
              Object.keys(timeLeft).map((interval) => (
                  <div key={interval} className="countdown-item_exchange">
                      <div className="countdown-time">{timeLeft[interval]}</div>
                      <div className="countdown-label">{interval}</div>
                  </div>
              ))
          ) : (
              <></>
          )}
      </div>
  );
}
function NFTMintBlock() {
  const { t, i18n } = useTranslation();
  const [nftCount, setNftCount] = useState(0);
  const totalSupply = 2200;

  const getNftCountNumberData = async()=>{
    const data = await getNftCountNumber()
    setNftCount(data.value)
  }

  useEffect(()=>{
    getNftCountNumberData()
  },[])
  

  const rarityChances = [
    { name: "COMMON", chance: "80%", image: common, background: "linear-gradient(90deg, #B5B5B5, #B5B5B5)", textColor: "#D3D3D3" },
    { name: "RARE", chance: "10%", image: rare, background: "linear-gradient(90deg, #00ACF9, #44307D)", textColor: "#07a6f2" },
    { name: "EPIC", chance: "7%", image: epic, background: "linear-gradient(90deg, #D000FF, #7700FF)", textColor: "#D000FF" },
    { name: "LEGEND", chance: "3%", image: legend, background: "linear-gradient(90deg, #FFDD00, #F87C00)", textColor: "#FDE400" },
  ];

  const handleMint = () => {
    openLink("https://8legends.ai/drops/0x1126b9c3EC84583C332979975b8f139d113AD88e")

    if (nftCount > 0) {
      // setNftCount(nftCount - 1);
      // alert("NFT minted successfully!");
    } else {
      // alert("No NFTs left to mint!");
    }
  };
  const promoUrl = (i18n.language === 'ru' || i18n.language === 'uk')
  ? "https://www.youtube.com/watch?v=4U8iAfWhycU&t=1s"
  : "https://www.youtube.com/watch?v=d02u0MDoqHA&t=2s";
  return (
    <>
      <Back />
      <div className='pump_top_container'>
        <div className='pump_title'>NIKICOIN - Tales of Telegramia</div>
        <img src={fon} className='background_pump' alt="Background"></img>
      </div>

      <div className='outer-container_pump'>
        <div className='container_glow_pump'>

        <div className="promo-container">
            <p className="promo-text">Limited NFT colection</p>
            <a className="promo-button" href={promoUrl} target="_blank" rel="noopener noreferrer">
                Learn more
                <img className="arrow-icon" src={pointer} alt="Иконка стрелки" />
            </a>
        </div>


          <div className="mint-info">
            <div className="nft-count">
              <div className='left_conatiner'>
                <span className='sp1'>
                  Only {nftCount} NFT 
                </span>
                <span className='sp2'>
                  left
                </span>
              </div> 
              <div className="progress-bar-container">
                <div
                  className="progress-bar"
                  style={{ width: `${ 100 - (nftCount / totalSupply) * 100}%` }}
                >

                </div>
                <div className='info_counter'>{nftCount}/{totalSupply}</div>

              </div>
            </div>
            <div className="extra-info">
              <span>First Bahamut collection</span>
              <span>up to 200% increased DROP</span>
              <span>8legends</span>
            </div>
            <div className="rarity-section">
              {rarityChances.map((rarity) => (
                <div key={rarity.name} className="rarity-card" >
                <img 
                    src={rarity.image} 
                    alt={`${rarity.name} icon`} 
                    style={{ 
                        boxShadow: `0px 0px 20px 0px ${rarity.textColor}` 
                    }} 
                />                  
                <div className="rarity-info">
                    <p style={{ background: `${rarity.background}`, color: rarity.textColor }}>{rarity.name}</p> {/* Text color from the updated rarityChances */}
                    <span style={{ color: rarity.textColor }}>{rarity.chance} chance</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <button className="mint-button" onClick={handleMint}>
            {/* MINT NOW */}
            <Countdown endDate ='2024-11-15T15:00:00' />
          </button>
        </div>
      </div>
    </>
  );
}

export default NFTMintBlock;
