import React, { useState, useEffect, useCallback } from 'react';
import './EarningsComponent.scss';
import crystalIcon from '../../../pages/gem.png';
import { getPumpTaskInfo, completePumpTask } from '../../../http';
import { useTranslation } from "react-i18next";
import { useAppData } from '../../../AppContext';
import wrong from "./❌.png";
import correct from "./🎉.png";

const EarningsComponent = ({ task, getPumpTasksData,getPotentialUsdtSectionData}) => {
  const [answer, setAnswer] = useState(null);
  const [show, setShow] = useState(false);
  const [taskCompleted, setTaskCompleted] = useState(task.is_finished);
  const [info, setInfo] = useState(null);
  const [showQuestion, setShowQuestion] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [correctConfetti, setCorrectConfetti] = useState([]);
  const [incorrectConfetti, setIncorrectConfetti] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null); 
  const [timeLeft, setTimeLeft] = useState(null);
  
  const confettiCount = 10;

  const { handleEditCoins, handleEditDiamonds } = useAppData();
  const { i18n } = useTranslation();

  const formatNumber = (num) => num >= 1000000 ? (num / 1000000).toFixed() + 'M' : num >= 1000 ? (num / 1000).toFixed() + 'K' : num;

  const handleNext = () => {
    if (currentStep < info.instructions.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      setShowQuestion(true); 
    }
  };

  const resetTask = () => {
    setAnswer(null);
    setSelectedOption(null);
    setShowQuestion(false);
    setCurrentStep(0);
  };

  const closeModal = async (event) => {
    if (event) event.stopPropagation(); 
    await getPumpTasksData()
    await getPotentialUsdtSectionData();
    setShow(false);
    resetTask();
  };

  const modal = document.querySelector('.modal');
  const modalWidth = modal?.offsetWidth || window.innerWidth;
  const modalHeight = modal?.offsetHeight || window.innerHeight;



  const initializeCorrectConfetti = useCallback(() => {
    const newConfetti = Array.from({ length: confettiCount }, () => ({
      img: correct,
      x: Math.random() * modalWidth,
      y: -Math.random() * modalHeight,
      speed: Math.random() * 2 + 1,
    }));
    setCorrectConfetti(newConfetti);
  }, [confettiCount]);

  const initializeIncorrectConfetti = useCallback(() => {
    const newConfetti = Array.from({ length: confettiCount }, () => ({
      img: wrong,
      x: Math.random() * modalWidth,
      y: -Math.random() * modalHeight,
      speed: Math.random() * 2 + 1,
    }));
    setIncorrectConfetti(newConfetti);
  }, [confettiCount]);

  const updateCorrectConfettiPosition = useCallback(() => {
    setCorrectConfetti((prevConfetti) =>
      prevConfetti.map((confetti) => ({
        ...confetti,
        y: (confetti.y + confetti.speed) % window.innerHeight,
      }))
    );
  }, []);

  const updateIncorrectConfettiPosition = useCallback(() => {
    setIncorrectConfetti((prevConfetti) =>
      prevConfetti.map((confetti) => ({
        ...confetti,
        y: (confetti.y + confetti.speed) % window.innerHeight,
      }))
    );
  }, []);

  const completePumpTaskData = async (pump_task_id, answer_id) => {
    setSelectedOption(answer_id);
    const data = await completePumpTask(pump_task_id, answer_id);
    setAnswer(data.status); 

    if (data.status === 'Answer is correct' || data.status === 'Answer is correct, but task is already completed') {
      initializeCorrectConfetti();
      const interval = setInterval(updateCorrectConfettiPosition, 30);
      setTimeout(() => clearInterval(interval), 2000); 
      setTaskCompleted(true); 

    } else {
      initializeIncorrectConfetti();
      const interval = setInterval(updateIncorrectConfettiPosition, 30);
      setTimeout(() => clearInterval(interval), 2000); 
      setTaskCompleted(true); 

    }
  };

  const getPumpTaskInfoData = async () => {
    if (!info) {
      const data = await getPumpTaskInfo(i18n.language, task.pump_task__id);
      setInfo(data);
    }
    setShow(true);
  };

  const complete = async () => {
    handleEditCoins(task.pump_task__coins_reward);
    handleEditDiamonds(task.pump_task__diamonds_reward);
    await getPumpTasksData(); 
    await getPotentialUsdtSectionData();
    closeModal();
    resetTask();
  };

  useEffect(() => {
    if (task.next_attempt_time!==null) {
      const interval = setInterval(() => {
        const now = Math.floor(Date.now() / 1000);
        const newTimeLeft = task.next_attempt_time - now;
        setTimeLeft((prev) => (newTimeLeft > 0 ? newTimeLeft : null)); 
        if (newTimeLeft <= 0) clearInterval(interval); 
      }, 1000);
  
      return () => clearInterval(interval); 
    }
  }, [task.next_attempt_time, taskCompleted]);
  
  const formatTimeLeft = (seconds) => {
    if (seconds === null) return ""; 
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };
  
  return (
      <div className="earnings-component" onClick={task.is_available ? () => getPumpTaskInfoData() : null}>

      {!task.is_available&&<div className='locked_task'></div>}

      <div className="info-section">
        <img src={task.pump_task__logo} alt="coin" className="icon" />
        <p>{task.pump_task__question[i18n.language]}</p>
      </div>
      <div className="bonus-section">

      {timeLeft !== null ? (
        <div className="bonus-timer">
          <p>{formatTimeLeft(timeLeft)}</p>
        </div>
          ) : taskCompleted ? (
            <>
              <p className="percentage">✔️ +{task.pump_task__pump_percentage}%</p>
              <div className="bonus-details">
              </div>
            </>
          ) : (
            <div className="bonus-details">
              <div className='percentage_task'>+{task.pump_task__pump_percentage}%</div>
              <div className='flex-pump'>
                <div className="bonus-item">
                <img src={crystalIcon} alt="crystal" />
                <span>+{formatNumber(task.pump_task__diamonds_reward)}</span>
              </div>
              <div className="bonus-item">
                <img src="https://nikicoin.store/media/newcoin_NIKI/nikicoin00.png" alt="small coin" />
                <span>+{formatNumber(task.pump_task__coins_reward)}</span>
              </div>
            </div>
             

              
            </div>
          )}
      </div>

      {show  && (
        <div className="modal">
          <div className="container_glow_modal">
            <div className="modal-container">
              <span
                className="close"
                onClick={() => closeModal()}
              >
                &times;
              </span>
              <div className="modal_content_pump">
                <div className="modal-info">
                  {!showQuestion ? (
                    <div className="modal_pupm_container">
                      <h2>{info.question.text}</h2>
                      <div className="instruction-modal">
                        <p>{info.instructions[currentStep].text}</p>  
                      </div>
                      <button onClick={handleNext}>OK</button>
                    </div>
                  ) : (
                    <>
                      <h2>{info.question.text}</h2>
                      <ul className="tab_container">
                        {info.question.options.map((option, index) => (
                          <li 
                            key={index} 
                            className={`tab ${
                              (answer === 'Answer is correct' && index + 1 === selectedOption) ||
                              (answer === 'Answer is correct, but task is already completed' && index + 1 === selectedOption)
                                ? 'correct' 
                                : selectedOption === index + 1 && answer !== 'Completed succesfully'
                                ? 'incorrect'
                                : ''
                            }`}
                            onClick={() => completePumpTaskData(task.pump_task__id, index + 1)}
                          >
                            {option.text}
                        </li>
                        
                        ))}
                      </ul>
                    </>
                  )}

                  {taskCompleted && (
                    <div className="answer-feedback">
                     {answer === 'Answer is correct' ? (
                        <button className="button_thanks" onClick={complete}>Take reward</button>
                      ) : 
                      ['Answer is incorrect', 'Answer is correct, but task is already completed', 'Answer is incorrect, but task is already completed'].includes(answer) ? (
                        <button className="button_leave" onClick={closeModal}>Leave</button>
                      ) : null
                    }
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

        {(answer === 'Answer is correct' || answer === 'Answer is correct, but task is already completed') && (
          <>
            {correctConfetti.map((confetti, index) => (
              <div
                key={index}
                style={{
                  position: 'absolute',
                  left: confetti.x,
                  top: confetti.y,
                  zIndex: 100
                }}
              >
                <img src={confetti.img} alt="Confetti" className="confetti-icon" />
              </div>
            ))}
          </>
        )}


        {(answer === 'Answer is incorrect, but task is already completed' || answer === 'Answer is incorrect') && (        <>
          {incorrectConfetti.map((confetti, index) => (
            <div
              key={index}
              style={{
                position: 'absolute',
                left: confetti.x,
                top: confetti.y,
                zIndex: 100
              }}
            >
              <img src={confetti.img} alt="Incorrect Confetti" className="confetti-icon" />
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default EarningsComponent;
