import React,{useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import PlayOnPhone from './Components/PlayOnPhone/PlayOnPhone';
import './i18n';
import SiteDisabled from './Components/SiteDisabled/SiteDisabled';
import { TonConnectUIProvider } from '@tonconnect/ui-react';

const root = ReactDOM.createRoot(document.getElementById('root'));

    let platform = window.Telegram.WebApp.platform;

    if (platform != 'ios'&& platform != 'android') {
           root.render(
                <PlayOnPhone />
            ); 
        } else {
            root.render(
                <App />
            );
     }

    //  root.render(
    //     <SiteDisabled />
    // );

    root.render(
        <TonConnectUIProvider manifestUrl="https://nikidev.pages.dev/manifest.json">
             <App />
        </TonConnectUIProvider>
    );