import React, { useState } from 'react';
import '../Instruction/instruction.scss';
import { useTranslation } from 'react-i18next';
import Countdown from '../Instruction/Countdown';
import listing from './listing.png'
import TopComponent from './TopComponent';

function Listing(props) {
    const { setShow } = props;
    const { t } = useTranslation();

    return (
        <div className='outer_container_listing'>
            <div className='container_glow_listing'>
                <span className="close" onClick={() => setShow(false)}>&times;</span>

                <TopComponent/>

            
            </div>
        </div>
    );
}

export default Listing;
