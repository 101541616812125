import React, { useState, useEffect} from 'react';
import '../Instruction/instruction.scss';
import listing from './listing.png'
import image1 from "./1.png";
import image2 from "./2.png";
import image3 from "./3.png";

function Countdown({ endDate }) {
  
    const calculateTimeLeft = () => {
        const difference = +new Date(endDate) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    }, [timeLeft]);

    return (
        <div className="countdown_exchange">
            {Object.keys(timeLeft).length > 0 ? (
                Object.keys(timeLeft).map((interval) => (
                    <div key={interval} className="countdown-item_exchange">
                        <div className="countdown-time">{timeLeft[interval]}</div>
                        <div className="countdown-label">{interval}</div>
                    </div>
                ))
            ) : (
                <></>
            )}
        </div>
    );
}

const data = {
  "blocks": [
    {
      "header": "Increase your DROP $NIKI",
      "image": image1,
      "endDate": '2024-11-15T15:00:00',
      "buttons": [
        {
          "text": "NIKI NFT"
        },
        {
          "text": "LIMITED COLLECTION"
        },
        {
          "text": "2220x"
        }
      ]
    },
    {
      "header": "$NIKI PUMP academy MAKE 1000X",
      "image": image2,
      "endDate": '2021-10-25T00:00:00',
      "buttons": [
        {
          "text": "ETH"
        },
        {
          "text": "SMALL MARKET CAP"
        },
        {
          "text": "1000x GEM"
        }
      ]
    },
    {
      "header": "CEX LISTING DROP $NIKI",
      "image": image3,
      "endDate": '2025-03-23T00:00:00',
      
    }
  ]
};

function TopComponent() {

    return (
      <div className="niki-drop-container flex_exchange">
        {data.blocks.map((block, index) => (
          <div 
            key={index} 
            className="niki-drop"
            style={{
              background: index === 1 
                ? 'linear-gradient(45deg, #02e8fd, #171e7e)' 
                : index === 2 
                ? 'linear-gradient(45deg, #c8c107, #834923)' 
                : 'linear-gradient(45deg, #137fb7, #720f71)'
            }}
          >
            {/* {index === 0 && <div className='coming_soon_block'>Coming Soon</div>} */}
            <div className="niki-drop-countdown">
              <Countdown endDate={block.endDate} />
            </div>
            
            <div className="niki-drop-header">
              <h1>{block.header}</h1>
              <img src={block.image} alt="block character" className={index === 1  ? "character-image class": "character-image "}/>
            </div>
            <div className="niki-drop-content">
              <div className="niki-drop-buttons ">
                {block.buttons?.map((button, btnIndex) => (
                  <button key={btnIndex} className="niki-button">
                    {button.text}
                  </button>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
}

function ListingLoading(props) {
 
    return (
        <div className='outer_container_listing'>
            <div className='container_glow_listing'>

                <TopComponent/>


            </div>
        </div>
    );
}

export default ListingLoading;
